export const en = {
  activities: {
    book_appointment: "Book appointment",
    book_medical_referral_meeting: "Book medical referral meeting",
    exercise_1: "Sit-to-stand",
    exercise_10: "Hip rotation",
    exercise_11: "Leg kick",
    exercise_12: "Leg lift on back",
    exercise_13: "Leg lift on side",
    exercise_14: "Leg lift on stomach",
    exercise_15: "Lunges",
    exercise_16: "Standing leg curl",
    exercise_17: "Twisted leg lift",
    exercise_18: "Bridge",
    exercise_19: "Bird dog",
    exercise_2: "Stepping",
    exercise_20: "Lying core strengthener",
    exercise_21: "Sitting core strengthener",
    exercise_22: "Lying knee raise",
    exercise_23: "Side plank",
    exercise_24: "Plank",
    exercise_25: "Push-ups",
    exercise_26: "Finger dance",
    exercise_27: "Bend and flex",
    exercise_28: "Grip strengthener",
    exercise_29: "Stretch and lift",
    exercise_3: "Hip strengthening",
    exercise_30: "Wrist stabilizer",
    exercise_31: "Finger lift",
    exercise_32: "Opening grip",
    exercise_33: "Standing row",
    exercise_34: "Standing core strengthener",
    exercise_35: "Foreward arm raise",
    exercise_36: "Shoulder retraction",
    exercise_37: "Diagonal arm raise",
    exercise_38: "Side-lying rotation",
    exercise_39: "Serratus press",
    exercise_4: "Weight transfer",
    exercise_40: "Shoulder stretch",
    exercise_41: "Standing external rotation",
    exercise_42: "Relaxation",
    exercise_43: "Neck stretch ",
    exercise_44: "Chin tuck",
    exercise_45: "Neck stabilizer",
    exercise_46: "Lateral arm raises",
    exercise_47: "Neck strengthener",
    exercise_48: "Presses",
    exercise_49: "Shrugs",
    exercise_5: "Single leg balance",
    exercise_50: "Overhead press",
    exercise_51: "Thumb strengthener",
    exercise_52: "Single leg deadlift",
    exercise_53: "Side-lying clam",
    exercise_54: "Reverse fly",
    exercise_55: "Squat jacks",
    exercise_56: "Bicycle crunch",
    exercise_57: "Lying toe taps",
    exercise_58: "Swimmer",
    exercise_59: "Mountain climbers",
    exercise_6: "Lying prone",
    exercise_60: "Single leg heel raise",
    exercise_61: "Lying back twist",
    exercise_7: "Knee-to-chest",
    exercise_8: "Butterfly",
    exercise_9: "Crossed legs",
    exercise_success: "Great job on this one!",
    finish_form_1: "Follow-up questionnaire",
    followup_health_report: "Follow-up questionnaire",
    functionality_1: "Measure your physical function",
    goal_1: "What happens next?",
    guide_1: "Joint Academy introduction",
    health_care_professional_intro_1: "Your specialist",
    health_report_1: "Your health",
    introduction: "Introduction",
    lesson_1: "What is osteoarthritis?",
    lesson_10: "Stepping",
    lesson_11: "Weight Transfer",
    lesson_12: "Hip-Strengthening",
    lesson_2: "How do you know you have osteoarthritis?",
    lesson_3: "How is osteoarthritis treated?",
    lesson_4: "How to handle pain and other common symptoms",
    lesson_5: "Osteoarthritis and activities",
    lesson_6: "The importance of lifestyle",
    lesson_8: "Exercising with osteoarthritis",
    lesson_9: "Sit-to-Stand",
    meet_your_pt: "Meet your physical therapist",
    part: "Part {number}",
    patient_claim_1: "Patient fee",
    payment_1: "Your Sustain subscription",
    report_1: "Weekly report",
    report_extended_1: "Weekly report",
    self_care_activation: "Time to continue on your own",
    subscription_1: "Coverage",
    sustain_intro_1: "Welcome to the Sustain Program!",
    text_lesson_1: "About Osteoarthritis",
    text_lesson_10: "About Motivation",
    text_lesson_11: "Weight Management",
    text_lesson_12: "The Importance of Sleep",
    text_lesson_13: "Strength & Cardio Exercise",
    text_lesson_14: "Healthy Eating",
    text_lesson_15: "Osteoarthritis & Work",
    text_lesson_17: "Joint Replacement Surgery",
    text_lesson_19: "Research on Healthy Eating",
    text_lesson_2: "Risk Factors for Osteoarthritis",
    text_lesson_20: "More About Surgery",
    text_lesson_21: "Strength, Mobility & Cardio",
    text_lesson_22: "Traveling with Osteoarthritis",
    text_lesson_23: "Exercising Before Surgery",
    text_lesson_24: "Managing Setbacks",
    text_lesson_25: "Dietary Supplements & Over-The-Counter Drugs",
    text_lesson_26: "Let Your Dog Help You",
    text_lesson_27: "Making Exercise More Fun",
    text_lesson_28: "Weight-Loss Progress",
    text_lesson_3: "Managing Your Symptoms",
    text_lesson_4: "Finding Your Diagnosis",
    text_lesson_5: "Exercise & Body Weight",
    text_lesson_6: "Managing Your Pain",
    text_lesson_7: "Exercise & Health",
    text_lesson_8: "Noninvasive Treatments",
    text_lesson_9: "Invasive Treatments",
    text_lesson_lbp01: "About Low Back Pain",
    text_lesson_lbp02: "Getting a Diagnosis",
    text_lesson_lbp03: "Anatomy of the Back",
    text_lesson_lbp04: "Common Causes of Back Pain",
    text_lesson_lbp05: "Less Common Causes of Back Pain",
    text_lesson_lbp06: "About Pain",
    text_lesson_lbp07: "Pain Management",
    text_lesson_lbp08: "Fear of Movement",
    text_lesson_lbp09: "Ergonomics",
    text_lesson_lbp10: "Physical Activity & Back Pain",
    text_lesson_lbp11: "Exercise & Back Pain",
    text_lesson_lbp12: "Other Treatment Options",
    text_lesson_lbp13: "About Low Back Surgery",
    text_lesson_lbp14: "Myths & Facts",
    text_lesson_lbp15: "Sleep & Pain",
    update_payment_method_1: "Upgrade your plan",
    w1_health_report: "Your health",
    weekly_health_report: "Weekly health report",
    payment_details: "Payment details",
  },
  buttons: {
    add: "Add",
    add_new_goal: "Add new goal",
    add_record: "Add record",
    cancel: "Cancel",
    change: "Change",
    change_language: "Change language to Swedish",
    confirm: "Confirm",
    confirm_sign: "Yes, sign",
    create: "Create",
    create_password: "Save new password",
    delete: "Delete",
    delete_goal: "Delete all goals",
    edit: "Edit",
    edit_goal: "Edit goals",
    invite: "Invite",
    no: "No",
    off: "Off",
    ok: "OK",
    on: "On",
    save: "Save",
    search: "Search",
    send_invitation: "Send invitation",
    sign_in_with_google: "Sign in with Google",
    sign_in: "Sign in",
    update: "Update",
    upload: "Upload",
    yes: "Yes",
    yes_sign: "Yes, sign",
    remove: "Remove",
  },
  care_event_configurations: {
    configuration: "Configuration",
    id: "Id",
    label: "Care event configurations",
    name: "Name",
    other: "Other",
    payer: "Payer",
    tables: {
      no_configs_found: "No configurations found",
    },
    weekly_config: "Weekly configuration",
  },
  change_therapist: {
    remove_substitute: "Remove sub",
  },
  common: {
    compare_to_original: "Compare to original",
    created_at: "Created at",
    dos: "DOS",
    expires: "Expires",
    modified: "Modified",
    original: "Original",
    signed: "Signed",
  },
  create_plan_of_care: {
    box_button: "Create plan of care",
  },
  dashboard: {
    tables: {
      no_patients_found: "No patients found",
      no_therapists_found: "No therapists found",
      notes: {
        types: {
          PROTOCOL_DISCHARGE: "Discharge note",
          PROTOCOL_MONTHLY: "Monthly note",
          PROTOCOL_QUARTERLY: "Quarterly follow-up call",
          PROTOCOL_WEEKLY: "Treatment note { week_number }",
          PROTOCOL_WEEKLY_W0: "Kick-off call",
          PROTOCOL_WEEKLY_W1: "Treatment note",
          PROTOCOL_WEEKLY_W10: "Treatment note 9",
          PROTOCOL_WEEKLY_W11: "Treatment note 10",
          PROTOCOL_WEEKLY_W11_AND_W12: "Treatment note 10-11",
          PROTOCOL_WEEKLY_W12: "Treatment note 11",
          PROTOCOL_WEEKLY_W13: "Treatment note 12",
          PROTOCOL_WEEKLY_W14: "Treatment note 13",
          PROTOCOL_WEEKLY_W15: "Treatment note 14",
          PROTOCOL_WEEKLY_W16: "Treatment note 15",
          PROTOCOL_WEEKLY_W17: "Treatment note 16",
          PROTOCOL_WEEKLY_W18: "Treatment note 17",
          PROTOCOL_WEEKLY_W19: "Treatment note 18",
          PROTOCOL_WEEKLY_W2: "Treatment note 1",
          PROTOCOL_WEEKLY_W20: "Treatment note 19",
          PROTOCOL_WEEKLY_W3: "Treatment note 2",
          PROTOCOL_WEEKLY_W4: "Treatment note 3",
          PROTOCOL_WEEKLY_W5: "Treatment note 4",
          PROTOCOL_WEEKLY_W6: "Follow-up call",
          PROTOCOL_WEEKLY_W7: "Treatment note 6",
          PROTOCOL_WEEKLY_W7_AND_W8: "Treatment note 6-7",
          PROTOCOL_WEEKLY_W8: "Treatment note 7",
          PROTOCOL_WEEKLY_W9: "Treatment note 8",
          PROTOCOL_WEEKLY_W9_AND_W10: "Treatment note 8-9",
        },
      },
    },
  },
  dropdown: {
    logout: "Sign out",
  },
  errors: {
    claim_is_badly_formatted: "Claim is badly formatted",
    could_not_assign_substitute_therapist: "Could not assign substitute therapist",
    could_not_change_available_to_substitute: "Could not change available to substitute",
    could_not_change_hcp: "Could not update Health Care Provider",
    could_not_change_patient_inflow: "Could not change patient inflow",
    could_not_change_video_call_preferences: "Could not change video call preferences",
    could_not_close_account: "Could not close account",
    could_not_create_record: "Could not create record",
    could_not_create_referral_source: "Could not create referral source",
    could_not_delete_document: "Could not delete document",
    could_not_delete_record: "Could not delete record",
    could_not_register_time_off: "Could not register time off",
    could_not_remove_offline_visit_and_billing: "Could not remove offline visit and billing",
    could_not_reopen_account: "Could not reopen account",
    could_not_reopen_treatment: "Could not reopen treatment",
    could_not_resubmit_claim: "Could not resubmit claim",
    could_not_send_invite: "Could not send invite",
    could_not_sign_in: "Could not sign in",
    could_not_update_activity: "Could not update activity",
    could_not_remove_activity: "Could not remove activity",
    could_not_update_personal_information: "Could not update personal information",
    could_not_update_record: "Could not update record",
    could_not_update_state_licences: "Could not update state licences",
    could_not_upload_abn: "Could not upload ABN document",
    could_not_upload_poc: "Could not upload plan of care",
    could_not_upload_referral: "Could not upload referral",
    generic: "Something went wrong. Try again.",
    generic_login: "Something went wrong. Please try again later.",
    generic_nodata: "No data",
  },
  form: {
    accepting_patients: "Accepting patients",
    add_stripe_customer: {
      button: "Create Account",
      could_not_create: "Could not create Stripe Customer",
      siren: "SIREN",
      stripe_account: "Stripe Account",
      successfully_created: "Successfully created Stripe Customer",
      title: "Add Stripe Customer",
    },
    address: "Address",
    adherence_appointment_on_sudden_inactivity: "Check-in call for inactivity",
    assign_new_therapist: "Assign new therapist",
    assign_temporary_therapist: "Substitute therapist",
    available_for_patients: "Available for patients",
    available_to_substitute: "Available to substitute",
    birth: "Date of birth",
    by: "By",
    by_category: "By category",
    care_event: "Care event",
    change_hcp: {
      header: "Change Health Care Provider",
    },
    changed_hcp: {
      header: "Health Care Provider has been changed",
    },
    change_payer: "Change Payer",
    comment: "Comment",
    completed_date: "Completed date",
    country: "Country",
    create_new_health_care_provider: "Create new Health Care Provider",
    health_care_provider_invitation_payer: "Payer for patient invitations",
    create_plan_of_care: "Create plan of care",
    custom_welcome_message: "Custom welcome message",
    date: "Date",
    date_of_birth: "Date of birth",
    date_of_physician_signature: "Signed by physician at ",
    date_of_service: "Date of service",
    delete_unclaimed_referral: "Do you want to delete this unclaimed medical referral?",
    doctor_id: "Doctor Id",
    document_uploaded: "Document uploaded",
    drop_in_calls_push: "Drop-in Calls",
    duration_days: "Duration days",
    email: "Email",
    expires_at: "Expires at",
    find_patients_in: "Find patients in",
    find_therapists_in: "Find therapists in",
    first_name: "First Name",
    functionality_test: "Functionality Test",
    health_care_provider: "Health Care Provider",
    health_report: {
      header: "Health Report",
    },
    home_timezone: "Home timezone",
    profession: "Profession",
    icd_codes: "Diagnosis code",
    in_selfcare: "Is in selfcare",
    initial_pain_rating: {
      header: "Initial Pain Rating",
    },
    invitation_successfully_sent_to: "Invitation successfully sent to",
    invite_a_therapist: "Invite a therapist",
    invite_doctor: "Invite doctor",
    invite_partner: "Invite partner",
    last_name: "Last Name",
    name: "Name",
    new_clinic: "Add new clinic",
    new_record: "New record",
    new_referral_source: "New referral source",
    no_care_events: "No Care Events",
    no_signed_care_events: "No signed care events",
    note_type: "Note type",
    option: "Option",
    options: {
      option_1: "1",
      option_2: "2",
      option_3: "3",
    },
    pain_rating: "Pain rating (0-10)",
    patient_first_name: "Patient's first name",
    patient_last_name: "Patient's last name",
    payer: "Payer name (optional)",
    payer_category: "Payer category",
    payer_name: "Payer",
    phone: "Phone",
    phone_calls: "Phone calls",
    phone_number: "Phone Number",
    preferred_name: "Preferred name",
    provider: "Provider",
    provider_first_name: "Provider's first name",
    provider_last_name: "Provider's last name",
    provider_npi: "Provider NPI",
    reason: "Reason",
    repetitions: "Repetitions",
    select_document_type_to_upload: "Select document type to upload",
    selfcare: "Self-care",
    show_metrics: "Show metrics",
    signed_at: "Signed at",
    signed_care_events_only: "Signed care events only",
    source: "Source",
    state: "Patient state",
    state_licenses: "State licenses",
    state_zip: "State/Zip",
    stripe_customer: "Stripe Customer",
    success: "Success",
    successfully_changed_hcp_to: "Successfully changed HCP to",
    suggest_adherence_appointment: "Suggest Adherence Appointment",
    type: "Type",
    upload_abn: "Upload ABN document",
    upload_abn_document: "Upload ABN document",
    upload_poc: "Upload Plan of care",
    value: "Value",
    video_calls: "Video calls",
    voice_calls: "Voice calls",
    weekday: "Weekday",
    weekly_patient_assignment_limit: "Weekly patient assignment limit",
    welcome_message_only_extra: "Disable default welcome message",
    which_clinic: "Which clinics does the partner manage?",
  },
  fr_invoices: {
    label: "FR Invoices",
    month_label: "See invoices for",
    table: {
      care_events: "Total Care Events",
      supplementary: "Supplementary events",
      therapist: "Therapist",
      video_calls: "Video calls",
    },
  },
  insurance: {
    copay: "Copay",
    copay_estimate_cents: "Copay estimate cents",
    could_not_update_insurance: "Could not update insurance",
    could_not_remove_insurance: "Could not remove insurance",
    medicare: "Medicare",
    medicare_amount_used: "Medicare amount used",
    medicare_usage: "Medicare Usage",
    memberid: "Member ID",
    none: "None",
    patient_eligible: "Patient is eligible",
    primary: "Primary Insurance",
    secondary: "Secondary Insurance",
    update_primary_insurance: "Update primary insurance",
    update_secondary_insurance: "Update secondary insurance",
  },
  manage_treatments: {
    activate_button_label: "Activate",
    activate_for: "Activate {{joint}} for {{scope}}?",
    activate_treatment: "Activate",
    activate_treatment_error_message: "Error activating treatment",
    activated_header: "Activated!",
    activation_warning: "Note! This action can not be turned off after activation (it can only be triggered again).",
    all_current_therapists: "All current therapists",
    all_incoming_patients: "All incoming patients",
    all_incoming_therapists: "All incoming therapists",
    cancel_button_label: "Cancel",
    fetch_treatments_error_message: "Error fetching treatments",
    hand: "Hand",
    header: "Manage joints in",
    label: "Manage joints",
    lower_back: "Lower back",
    neck: "Neck",
    shoulder: "Shoulder",
    trigger_treatment: "Trigger",
    trigger_treatment_again: "Trigger again",
  },
  messages: {
    substitute_therapist_change: {
      end: "This patient has been reassigned to you from {therapistName}",
      start: "This patient has been temporarily assigned to you from {therapistName}",
      start_with_end_date:
        "This patient has been temporarily assigned to you from {therapistName} until {assignmentEndDate}",
    },
  },
  none: "None",
  partner_patients: {
    name: "Name",
    treatment_started: "Treatment started",
  },
  partner_referral_table: {
    duration: "Duration",
    referral_issued: "Referral received",
  },
  patient: {
    deleted: "Deleted",
    discharged: "Discharged",
    exemption_card: "Exemption card",
    journey_in_selfcare: {
      false: "False",
      true: "True",
    },
    journey_state: {
      blocked: "Blocked",
      discharged: "Discharged",
      in_custom_course: "In custom course",
      in_first_health_form: "In first health form",
      in_introduction: "In introduction",
      in_selfcare: "In selfcare",
      in_six_week_course: "In six week course",
      in_sustain_course: "In sustain course",
      invited: "Invited",
      new: "New",
      referred_elsewhere: "Referred elsewehere",
      treatment_ended: "Treatment ended",
    },
    medical_records: {
      initial_pain_rating: "Initial pain rating",
    },
    premium_type: "Subscription",
    premium_types: {
      none: "No subscription",
      selfcare: "Self care",
      guided_care: "Guided care",
    },
    pt_on_demand: {
      pt_on_demand: "PT on demand",
      pt_on_demand_enabled: "PT-on-demand enabled",
    },
    remove_item_title: "Are you sure you want to remove {{name}} from patient?",
  },
  patients: {
    account: "Account",
    actions: "Actions",
    activities: "Activities",
    ailment: "Ailment",
    created: "Created",
    document: "Document",
    documents: "Documents",
    payer: "Payer",
    end_treatment_form: {
      close_account: "Close account",
      reasons: {
        other: "Other",
        acquired_tools: "Is appropriate to continue independently",
        ceased_activity: "Non compliant",
        cost: "Cost prohibitive",
        insurance_rejected: "Insurance not accepted",
        not_interested: "Patient not interested",
        not_selfcare: "Patient has been offered the Self-Care program but is not interested ",
        not_suitable: "Treatment not suitable",
        referred_elsewhere: "Referred to another healthcare provider",
        surgery: "Recently underwent or is scheduled for surgery",
        symptom_free: "Patient is symptom free",
        unreachable: "Unable to reach patient ",
        wrong_treatment: "Incorrect painful joint selected by patient in registration",
      },
    },
    ended_by: "Ended by",
    generate_uk_discharge_letter: "Generate UK discharge letter",
    handle: "Handle",
    header_info: {
      most_painful_joint_short: "Joint",
    },
    health: {
      difficulty_values: ["None"],
    },
    health_reports: {
      none: "None",
      questions_content: {
        none: "None",
        rmdq_none: "None of the above is true.",
      },
    },
    payers: {
      name: "Name",
    },
    id: "ID",
    invite_a_patient: "Invite a patient",
    invite_sent: "An invite has been sent!",
    lateral_location: {
      both: "Both",
      left: "Left",
      right: "Right",
      undefined: "undefined",
    },
    medical_records: {
      abn: "ABN",
      health_journal: "Health Journal",
      not_applicable: "N/A",
      personal_number: "Personal identity number",
      plan_of_care: "Plan of care",
      referral: "Referral",
      referral_for: "Referral for",
      social_security_number: "Social security number",
    },
    menu: {
      protocol: "Protocol",
    },
    no_activities: "No activities",
    no_documents: "No documents",
    notes: {
      discharge_visit_dropdown: {
        options: {
          none: "No billable communication",
        },
      },
      fields: {
        discharge_communication_options: {
          none: "No billable communication",
        },
      },
    },
    pain_location: {
      elbow: "Elbow",
      foot: "Foot",
      hand: "Hand",
      hip: "Hip",
      jaw: "Jaw",
      knee: "Knee",
      lower_back: "Lower back",
      neck: "Neck",
      shoulder: "Shoulder",
      spine: "Spine",
      upper_back: "Upper back",
      wrist: "Wrist",
      lower_leg: "Lower leg",
    },
    protocol: {
      awaiting_completion: "awaiting completion",
      change_levels: "Change levels",
      completed: "Completed",
      current: "Current",
      delay: "{days} days delay",
      levels_description:
        "Changes will apply to the patient's current exercises.\nSetting the level to zero means that the exercise is removed for the patient.",
      no_activities: "No activities scheduled",
      overdue: "This week has been put on hold until the patient completes the mandatory activities.",
      removed_exercise: "Removed exercise",
      required: "* This activity needs to be completed in order for the next week of the protocol to be enabled.",
    },
    remove_offline_visit_and_billing: "Remove offline visit and billing",
    reopen_account: "Reopen account",
    reopen_treatment: "Reopen treatment",
    revert_treatment_switch: "Revert switch",
    roles: {
      therapist: "Physical therapist",
    },
    state: "State",
    statuses: {
      active: "Active",
      deleted: "Account Closed",
      end_treatment: "Ending",
      inactive: "Inactive",
      referral_and_authorization: "Ref. & Pre-Auth",
      referral_needed: "Referral needed",
      requires_authorization: "Pre-Auth needed",
    },
    substitute_therapist: "Temporary",
    tool_tip: {
      substitute_therapist: "Substitute therapist",
    },
    treatment: "Current treatment",
    treatment_history: "Treatment history",
    uk_discharge_letter: {
      address: "Address: ",
      contact_at: "Contact at: ",
      contact_link: "support@jointacademy.co.uk",
      create: "Create",
      date: "Date: ",
      date_of_birth: "Date of birth: ",
      differential_diagnosis: "Differential diagnosis: ",
      discharge_further_action: "Action required",
      discharge_urgency: {
        routine: "Routine",
        urgent: "URGENT",
      },
      download: "Download",
      footer: {
        line_1: "Yours sincerely",
        line_2: "{{ therapistName }}, Physiotherapist",
        line_3: "If you require any further information, please do not hesitate to contact us at: ",
        line_4:
          "Your referrer (NHS) and your GP will receive a copy of this document. Please also share this information with any other people, or services involved in supporting you with your health and care needs.",
      },
      goal_achieved: "Goal achieved.",
      goal_not_achieved: "Goal not achieved.",
      goal_free_text: "Patient specific goals: ",
      goal_functionality: "Function goal: ",
      goal_pain: "Pain goal: ",
      goals: "Goals:",
      header: {
        line_1: "Registered offices: 11 Dudhope Terrace,",
        line_2: "Dundee, Scotland,",
        line_3: "DD3, 6TS",
      },
      nhs_number: "NHS number: ",
      management: "Management: ",
      medication: "Medications: ",
      past_medical_history: "Previous medical history: ",
      plan: "Plan: ",
      progression: "Progression: ",
      regarding: "Regarding: ",
      subjective: "Subjective: ",
      table: {
        adherence: "Adherence",
        bmi: "BMI",
        eq5d: "Quality of Life: EQ5D",
        function: "Function: 30 sec sit to stand",
        header: {
          measure: "Measure",
          outcome: "Outcome",
          pre: "Pre- Treatment",
          post: "Post- Treatment",
        },
        healthform_score: {
          fihoa: "Hand function FIHOA",
          hoos: "Hip function HOOS",
          koos: "Knee function KOOS",
          rmdq: "Back function RMDQ",
          npq: "Neck function NPQ",
          spadi: "Shoulder function SPADI",
          unknown: "Unknown",
        },
        nrs: "NRS pain (0-10)",
        outcome: {
          improved: "Improved",
          regressed: "Regressed",
          unchanged: "Unchanged",
        },
      },
      telephone: "Telephone: ",
      treatment_programme: "Treatment Programme: ",
    },
    view_all: "View all",
    visible_for_therapist: "Visible for therapist",
    week: "Week",
    weeks: "Weeks",
  },
  payers: {
    payers_in: "Payers in",
    table: {
      header: {
        id: "Id",
        name: "Name",
        code: "Code",
        category: "Category",
      },
      no_payers_found: "No payers could be found",
    },
    save_warn: "Before switching payer make sure no other operation needs to be done",
  },
  protocol: "Protocol",
  provider_group: {
    could_not_update: "Could not update provider group",
    label: "Provider groups",
    none: "None",
    title: "Provider group",
    update: "Update Provider Group",
    updated: "Provider group was successfully updated",
  },
  rcm: {
    bill: "Bill",
    care_event: "Care Event ID",
    cdm: "CDM",
    cdmfull: "Charge Description Matrix",
    claim_successfully_resubmitted: "Claim successfully resubmitted",
    claimid: "Claim ID",
    claims: "Claims",
    claimstitle: "Claims Overview",
    create: "Create",
    error_bill_invoice: "Couldn't bill the invoice",
    error_claim_generation: "Something went wrong when generating a claim!",
    error_create_invoice: "Couldn't create the invoice",
    generate_claim: "Generate Claim",
    generate_success: "Claim submitted!",
    icd: "ICD Code",
    insurancecompany: "Insurance Company",
    invoice_billed: "Billed",
    invoice_created: "Created",
    invoices: "Invoices",
    label: "Label",
    member_id: "Member ID",
    memberid: "Member ID",
    note: "Note",
    open_invoice_details: "Open invoice details",
    patientid: "Patient ID",
    payer: "Payer",
    payerstate: "Payer State",
    physician_id: "Physician id",
    referenceid: "Reference ID",
    resubmit: "Resubmit",
    resubmit_claim: "Resubmit claim",
    sentdate: "Sent Date",
    service: "Date of Service",
    state: "State",
    videocall: "Confirmed Video Call",
  },
  referral: {
    upload_document: "Upload document",
    upload_referral: "Upload referral",
    upload_referral_form: "Referral registration",
    upload_success: "Referral file uploaded for patient with id",
  },
  remove_sub_form: {
    remove_substitute: "Remove substitute therapist",
  },
  reports: {
    close_note: "Close note",
    note: "Note",
    note_does_not_exist: "Note does not exist",
    open_note: "Open note",
  },
  settings: {
    profile_information: {
      time_zone: "Time zone",
      weekly_assignment_limit: "Weekly patient limit:",
    },
  },
  substitute_therapist: "Substitute therapist",
  text_no: "No",
  text_yes: "Yes",
  therapist_profile: {
    feature_toggle_title: "Feature toggle",
    treatment_toggle_title: "Treatment toggle",
  },
  therapist: {
    change_hcp: {
      button: "Change HCP",
    },
    create_and_change_hcp: {
      button: "Create and change HCP",
    },
    reset_to_default_hcp: {
      button: "Reset to default HCP",
    },
  },
  therapists: {
    out_of_office: {
      active_until: "active until",
      end: {
        button: "End out of office",
        submit: "Back to work",
        title: "End out of office period",
      },
      start: {
        button: "Register time off",
        submit: "Submit",
        title: "Mark as out of office",
      },
    },
  },
  translations: {
    exercise_image_upload: "Update exercise image",
    exercise_level: "Exercise level",
    exercise_name: "Exercise name",
    phrase_branch: "Phrase branch",
    phrase_key: "Phrase key",
    select_image_button: "Select image file",
    submit_upload_image: "Upload image",
    text_lesson_cover_image_upload: "Update text lesson cover image",
    text_lesson_name: "Text lesson name",
    text_lesson_paragraph: "Pragraph in text lesson part",
    text_lesson_paragraph_image_upload: "Update text lesson paragraph image",
    text_lesson_part: "Text lesson part number",
    unclaimed_referral_upload: "Upload unclaimed referral",
    image_path: "Image path",
    locales: "Locales",
    translations: "Translations",
    update_exercise: "Update Exercise translations",
    update_functional_test: "Update Functional Test translations",
    update_text_lesson: "Update Text Lesson translations",
  },
  unclaimed_referral: {
    upload_success: "Referral file uploaded",
  },
  views: {
    other: "Other",
    patient: "Patient",
    patients: "Patients",
    payers: "Payers",
    lessonEditor: "Lesson Editor",
    rcm: "RCM",
    registry: "Registry",
    reports: "Reports",
    therapist: "Therapist",
    unclaimed_medical_referrals: "Unclaimed medical referrals",
  },
  lesson_editor: {
    editor_tab: "Editor",
    preview_tab: "Preview",
    images_tab: "Images",
    raw_tab: "⚠️ Raw",
    reset_example: "Reset example",
    clear: "Clear",
    identifier: "Identifier: ",
    target_joints: "Target joints: ",
    image_url_copied: "Image URL copied to clipboard\n\n{{url}}",
    part_divider: "Part {{number}}",
  },
};
