import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Note } from "@material-ui/icons";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { BoxButton, CDialog, Icon } from "shared";
import { useCareEvents } from "routes/patient/queries/useCareEvents";
import usePatient from "routes/patient/queries/usePatient";
import { Button, TextField } from "@material-ui/core";
import { useUkDischargeLetter } from "./useUkDischargeLetter";
import { DischargeLetter } from "./components";
import styled from "styled-components";

export const UkDischargeLetter: React.VFC = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);
  const { data: patient } = usePatient();
  const { data: careEvents } = useCareEvents();
  const { data: ukDischargeLetter } = useUkDischargeLetter();

  const [kickOffNote, setKickOffNote] = useState({});
  const [dischargeNote, setDischargeNote] = useState({});

  const [patientAddress, setPatientAddress] = useState(`${patient?.address}\n${patient?.city}\n${patient?.zip_code}`);
  const [patientNhsNumber, setPatientNhsNumber] = useState("");

  const [initialEQ5D, setInitialEQ5D] = useState("");
  const [latestEQ5D, setLatestEQ5D] = useState("");

  const [gpAddress, setGpAddress] = useState("");
  const [site, setSite] = useState("");

  useEffect(() => {
    const kickoffNote = careEvents?.find(
      careEvent => careEvent.label === "protocol_weekly_w0" && careEvent.review_state === "signed"
    );
    const dischargeNote = careEvents?.find(
      careEvent => careEvent.label === "discharge" && careEvent.review_state === "signed"
    );

    if (kickoffNote) {
      setKickOffNote(kickoffNote);
    }

    if (dischargeNote) {
      setDischargeNote(dischargeNote);
    }
  }, [careEvents]);

  const onCreateLetter = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    // For patient details
    setPatientAddress(target.patient_address.value);
    setPatientNhsNumber(target.patient_nhs.value);

    // For table content
    setInitialEQ5D(target.initial_eq5d.value);
    setLatestEQ5D(target.latest_eq5d.value);

    // For GP info
    setGpAddress(target.gp_address.value);
  };

  const getFileName = () => {
    return `${patient?.nhs_number} + ${patient?.first_name?.[0]}${patient?.last_name?.[0]} JA/CIC ${site} Discharge Letter`;
  };

  const getDischargeLetter = () => {
    return (
      <DischargeLetter
        patient={{
          first_name: patient?.first_name || "",
          last_name: patient?.last_name || "",
          birthday: patient?.birthday || "",
          address: patientAddress,
          phone_number: patient?.phone_number || "",
          nhs_number: patient?.nhs_number || patientNhsNumber,
          email: patient?.email || "",
          discharge_email_consent: patient?.discharge_email_consent || false,
          therapist: {
            first_name: patient?.therapist?.first_name || "",
            last_name: patient?.therapist?.last_name || "",
          },
        }}
        kickOffNote={kickOffNote}
        dischargeNote={dischargeNote}
        ukDischargeLetter={ukDischargeLetter}
        initialEQ5D={initialEQ5D}
        latestEQ5D={latestEQ5D}
        gpAddress={gpAddress}
      />
    );
  };

  return (
    <>
      <BoxButton startIcon={<Icon of={Note} />} onClick={openDialog}>
        {t("patients.generate_uk_discharge_letter")}
      </BoxButton>

      <CDialog
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
        open={isOpen}
        onClose={closeDialog}
        aria-labelledby="generate-uk-discharge-letter"
      >
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", minWidth: "250px" }}>
            <form onSubmit={onCreateLetter} style={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
              <StyledTextField
                name="patient_address"
                label="Patient address"
                onChange={e => setPatientAddress(e.target.value)}
                value={patientAddress}
                multiline
              />
              <StyledTextField name="patient_nhs" label="NHS number" />

              <StyledTextField name="initial_eq5d" label="Initial EQ5D" />
              <StyledTextField name="latest_eq5d" label="Latest EQ5D" />

              <StyledTextField name="gp_address" label="GP Address" multiline />
              <Button variant="contained" color="primary" type="submit">
                {t("patients.uk_discharge_letter.create")}
              </Button>
            </form>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <StyledTextField name="site" label="Site" onChange={e => setSite(e.target.value)} />
              <PDFDownloadLink document={getDischargeLetter()} fileName={getFileName()}>
                <Button variant="contained" color="primary">
                  {t("patients.uk_discharge_letter.download")}
                </Button>
              </PDFDownloadLink>
            </div>
          </div>

          <PDFViewer height="100%" width="100%" showToolbar={false}>
            {getDischargeLetter()}
          </PDFViewer>
        </div>
      </CDialog>
    </>
  );
};

const StyledTextField = styled(TextField)`
  margin-bottom: 8px;
`;
